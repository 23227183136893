import pkg from '../../../package.json';

export const environment = {
  appVersion: pkg.version,
  appName: pkg.name,
  production: true,
  COMPANY_NAME: 'University Network',
  COMPANY_ABB: 'un',
  SHOW_COUNTRY_SELECT: false,
  ALLOW_CHANGE_EMAIL_AND_PHONE: false,
  REGISTRATION_HOME_IF_DIFFERENT: true,

  CONTACT_INFO: [
    {
      infoIcon: "info-circle",
      infoValue: "2024 - University Network S.r.l. - Tutti i diritti riservati",
      infoUrl: null
    },
    {
      infoIcon: "environment",
      infoValue: "Sede legale: Via Giovanni Battista Pergolesi 8 20124, Milano",
      infoUrl: null
    },
    {
      infoIcon: "environment",
      infoValue: "Sede Operativa: Via Privata Bastia 7 20139, Milano",
      infoUrl: null
    },
    {
      infoIcon: "info-circle",
      infoValue: "Partita IVA: 12489530969",
      infoUrl: null
    },
    {
      infoIcon: "info-circle",
      infoValue: "We are part of eGroup",
      infoUrl: "https://egroup-italy.com/"
    }
  ],
  COUNTRY_SELECT: [
    {
      COUNTRY_NAME: 'Italia',
      COUNTRY_FLAG: 'it',
      COUNTRY_BASE_PATH: 'https://api.universitynetwork.it',
      COUNTRY_MEDIA_PATH: "https://dev-media-un.purplesoft.dev",
      COUNTRY_DEFAULT_LANGUAGE: 'it-it',
    },
  ],
  DEFAULT_PHONE_PREFIX : "+39",
  MESSAGE_DURATION: 2500,
  NEW_TRAN_SYMBOL: '',
  EDIT_TRAN_SYMBOL: '',
  EDIT_TRAN_AFFIX: 'booking_',
  ERROR_NOTIFICATION_DURATION: 0,
  LOADER_DELAY: 200,
  SAFARI_COLOR: '#ff0000',
  HTML_THEME_COLOR: '#ffffff',
  HTML_TITLE_COLOR: '#ffc40d',
  REGISTRATION_FULL_ADDRESS: false,
  USE_IUBENDA: false,
  
  IUBENDA_COOKIE_POLICY: 
  'var _iub = _iub || []; _iub.csConfiguration = {"countryDetection":true,"enableRemoteConsent":true,"enableUspr":true,"floatingPreferencesButtonDisplay":"bottom-right","perPurposeConsent":true,"siteId":2538161,"whitelabel":false,"gdprAppliesGlobally":false,"cookiePolicyId":81469193,"lang":"it", "banner":{ "acceptButtonCaptionColor":"#000000","acceptButtonColor":"#FECA00","acceptButtonDisplay":true,"closeButtonRejects":true,"customizeButtonCaptionColor":"#000000","customizeButtonColor":"#FECA00","customizeButtonDisplay":true,"explicitWithdrawal":true,"fontSizeBody":"12px","fontSizeCloseButton":"18px","listPurposes":true,"logo":null,"position":"bottom","rejectButtonCaptionColor":"#000000","rejectButtonColor":"#FFFFFF","rejectButtonDisplay":true,"showPurposesToggles":true }};',
  IUBENDA_CONS:
    'var _iub = _iub || {}; _iub.cons_instructions = _iub.cons_instructions || []; _iub.cons_instructions.push(["init", {api_key: "3bIDiM3EIufKwstNI1ATtrpphMC4k52s"}]);',
  IUBENDA_DEFAULT_HTML:
    '(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);',
  SHOW_PUBLIC_EVENT: false,
  SHOW_EVENTS_SALE_NAME: true,
  SHOW_CHEK_IN_INFO: false,
  SHOW_ALL_PRIVATE_SALE_CONTACTS: false,
  SHOW_MEMBERSHIPS: false,
  REGISTER_TYPE: 'full',
  DEFAULT_MENU_LEVEL: 1000,
  SOCIAL_IG:true,
  SOCIAL_FB:true,
  SOCIAL_LK:true,
  SOCIAL_WA:false
};
